<template>
  <div class="content-account">
    <div class="content-global content-tables-account">
      <div ref="searchAccount">
        <header-component-global
          sTextTitle="Cuentas financieras"
          sTextBtnAdd="Crear cuenta +"
          :bAdminPermissions="bAdminAccount"
          setDialog="setDialogAddAccount"
          @setDialogAdd="setDialogAddAccount"
        />
      </div>
      <div ref="filterGlobal">
        <filter-component-global
          class="global-filters"
          labelName="Buscador de cuenta financiera"
          placeholderName="Ingrese nombre de la cuenta..."
          @setSearch="setSearch"
          @setInstitution="setInstitution"
          @setCurrency="setCurrency"
          @setAccountBalance="setAccountBalance"
          @setAccountType="setAccountType"
        />
      </div>
      <div dark class="global-content-table">
        <no-data-text-component-global
          :iHeight="screenHeight"
          :sTextEmpty="sTextEmpty"
          :bAdminPermissions="bAdminAccount"
          setDialog="setDialogAddAccount"
          @setDialogAdd="setDialogAddAccount"
          v-if="bEmptyTable"
        />
        <v-data-table
          v-else
          v-show="!isMobile"
          mobile-breakpoint="0"
          :headers="headers"
          :disable-sort="true"
          :items="aTable"
          :loading="bLoadingTable"
          loading-text="Cargando datos..."
          :items-per-page="iItemsPerPage"
          :hide-default-footer="true"
          no-data-text="No hay información disponible para mostrar."
          :footer-props="{
            'items-per-page-text': 'Filas por página',
            'items-per-page-all-text': 'Todos',
          }"
          class="global-table"
        >
          <template slot="progress">
            <v-progress-linear
              color="var(--primary-color-loading-table)"
              indeterminate
            ></v-progress-linear>
          </template>
          <template v-slot:[`item.dAccountBalance`]="{ item }">
            <span :class="item.dAccountBalance > 0 ? 'class-in' : 'class-out'">
              <span>{{ item.dAccountBalance > 0 ? "" : "" }}</span>
              {{ item.dAccountBalanceFormat }}</span
            >
          </template>
          <template v-slot:[`item.accions`]="{ item }">
            <v-btn
              @click="detailAccount(item)"
              class="global-btn-table-detail"
              icon
            >
              <v-icon> mdi-eye-outline </v-icon>
            </v-btn>
            <v-btn
              v-show="bAdminAccount"
              @click="deleteItem(item)"
              class="global-btn-table-delete"
              icon
            >
              <v-icon> mdi-trash-can-outline </v-icon>
            </v-btn>
          </template>
          <template v-slot:footer>
            <footer-table-component-global
              :numPages="numPages"
              :totalItems="totalItems"
              @changeTable="getEmitAccounts"
            />
          </template>
        </v-data-table>
        <table-mobile-accounts-component
          v-show="isMobile"
          @changeTable="getEmitAccounts"
          @openDetailItem="detailAccount"
          @openDeleteItem="deleteItem"
          :aTable="aTable"
          :headers="headers"
          :numPages="numPages"
          :totalItems="totalItems"
        />
        <!-- <div class="content-btns-actions-accounts">
          <div class="content-btn-excel">
            <v-btn @click="setDialogGenerateReport()" class="btn-add-excel global-btn-primary">
              Exportar información
            </v-btn>
          </div>
        </div> -->
      </div>
    </div>
    <!-- <footer-component-global /> -->
    <dialog-add-accounts-component
      :dDialogAddAccount="dDialogAddAccount"
      @setDialogAddAccount="setDialogAddAccount"
    />
    <delete-component-global
      @setDialogDelete="deleteItem"
      :oDialogDelete="oDialogDelete"
      :bDialogDelete="bDialogDelete"
    />
    <!-- <dialog-account-generate-report
      @setDialogGenerateReport="setDialogGenerateReport"
      :bDialogGenerateReport="bDialogGenerateReport"
      :oDialogGenerateReport="oDialogGenerateReport"
    /> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      screenHeight: 0,
      heightMax: 0,
      sInstitution: "",
      sCurrency: "",
      bAccountBalance: null,
      sAccountTypeId: [],
      screenHeight: 0,
      screenWidth: 0,
      bLoadingTable: true,
      bEmptyTable: false,
      sTextEmpty: "",

      sSearch: "",
      iCurrentPage: 1,
      iPageNumber: 1,
      iItemsPerPage: 30,
      numPages: 0,
      totalItems: 0,
      totalView: 0,
      headers: [
        {
          text: "Nombre de la cuenta",
          sortable: false,
          value: "sName",
          class: "global-header-table  first-col-tb",
          cellClass: "global-body-table  first-col-tb",
        },
        {
          text: "Institución",
          sortable: false,
          value: "sNameInstitution",
          class: "global-header-table ",
          cellClass: "global-body-table ",
        },
        {
          text: "Balance",
          sortable: false,
          value: "dAccountBalance",
          class: "global-header-table",
          cellClass: "global-body-table ",
        },
        {
          text: "Tipo de cuenta",
          sortable: false,
          value: "sAccountTypeName",
          class: "global-header-table ",
          cellClass: "global-body-table",
        },
        {
          text: "Acciones",
          value: "accions",
          align: "center",
          class:
            "global-header-table  global-header-accions global-col-header-accion",
          cellClass:
            "global-body-table global-body-accions global-col-body-accion",
        },
      ],
      aTable: [],
      aMonth: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ],
      fechaIngreso: "",
      textAlfabetico: "",
      isMobile: false,
      dDialogAddAccount: false,
      bDialogDelete: false,
      oDialogDelete: {
        active: false,
        textTitle: "",
        textDescription: "",
        textQuestion: "",
        api: "",
        returnToView: false,
      },
      // bDialogGenerateReport: false,
      // oDialogGenerateReport: {},
    };
  },
  beforeMount() {
    this.$store.commit("setTabPosition", { sTabPosition: 0 });
    this.$store.commit("setTabPositionOrderPayable", 0);

    this.setTextEmpty();
    this.setAccountType();
    this.getAccounts();
  },
  updated() {
    this.matchHeight();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    window.addEventListener("resize ", this.matchHeight);
    this.matchHeight();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
    window.addEventListener("resize ", this.matchHeight);
  },
  methods: {
    setSearch(sSearch) {
      this.sSearch = sSearch;
    },
    setInstitution(val) {
      this.sInstitution = val;
    },
    setCurrency(val) {
      this.sCurrency = val;
    },
    setAccountBalance(val) {
      this.bAccountBalance = val;
    },
    setAccountType(val) {
      this.sAccountTypeId = val;
    },
    setDialogGenerateReport() {
      this.bDialogGenerateReport = !this.bDialogGenerateReport;
    },
    //#region RESIZE PAGE
    matchHeight() {
      this.$nextTick((e) => {
        if (this.$refs.searchAccount !== undefined) {
          this.heightMax =
            this.$refs.searchAccount.clientHeight +
            this.$refs.filterGlobal.clientHeight;
          this.heightMax = this.heightMax + 90; //123 IS NAVBAR
        }
        if (window.innerWidth > 599) {
          this.isMobile = false;
          this.screenHeight = window.innerHeight - this.heightMax;
        } else {
          this.isMobile = true;
          this.screenHeight = window.innerHeight - this.heightMax;
        }
      });
    },
    handleResize: function () {
      this.matchHeight();
    },
    //#endregion RESIZE PAGE
    setDialogAddAccount() {
      this.dDialogAddAccount = !this.dDialogAddAccount;
    },
    deleteItem(item) {
      if (item) {
        this.oDialogDelete.textTitle = "Eliminar cuenta";
        this.oDialogDelete.textDescription =
          "La eliminación de una cuenta es una acción irreversible.";
        this.oDialogDelete.textQuestion =
          "Acepto la responsabilidad y deseo proseguir con la eliminación de la cuenta: " +
          item.sName +
          ".";
        this.oDialogDelete.api = `${URI}/api/sp/v1/accounts/${item.sAccountId}`;
      }
      this.bDialogDelete = !this.bDialogDelete;
    },
    detailAccount(item) {
      this.$router
        .push(`/home/account-detail/${item.sAccountId}`)
        .catch(() => {});
    },
    getEmitAccounts(obj) {
      this.iCurrentPage = obj.iCurrentPage;
      this.iItemsPerPage = obj.iItemsPerPage;
      this.getAccounts();
    },
    getAccounts() {
      this.$store
        .dispatch("getPermissionsByUserGlobal")
        .then((resp) => {
          DB.get(`${URI}/api/sp/v1/accounts`, {
            params: {
              iPageNumber: this.iCurrentPage,
              iItemsPerPage: this.iItemsPerPage,
              sSearch: this.sSearch,
              sCurrencyId: this.sCurrency,
              sFinancialInstitutionId: this.sInstitution
                ? this.sInstitution
                : null,
              eSortBalance: this.bAccountBalance,
              sAccountTypeId: this.sAccountTypeId,
            },
            headers: {
              Authorization: "Bearer " + this.$store.state.sToken,
            },
          })
            .then((response) => {
              this.numPages = response.data.numPages;
              this.totalItems = response.data.total;

              this.aTable = response.data.results.map((e) => {
                return {
                  ...e,
                  sNameInstitution: e.oFinancialInstitution.sName,
                  sAccountTypeName: e.oAccountType.sName,
                  sCurrency: this.formatCurrency(e.oCurrency.sCurrencyId),
                };
              });
              this.bLoadingTable = false;
              this.bEmptyTable = this.aTable.length < 1;
              this.totalView = this.aTable.length;
              this.$store.commit("refresher", false);
            })
            .catch((error) => {
              this.bLoadingTable = false;
              this.Error(error.response.data);
            });
        })
        .catch((err) => {
          this.Error(err);
        });
    },
    formatCurrency(currency) {
      switch (currency) {
        case "cf37a729-0384-4ae0-8be8-60575e60c603":
          return "MXN";
        case "45459e8b-34f3-4232-9e18-bb75cf6e944a":
          return "USD";
        default:
          return "";
      }
    },
    setTextEmpty() {
      let sTextemptyTemp = " <p>No existen cuentas registradas.</p>";
      this.bAdminAccount
        ? (sTextemptyTemp +=
            "<p>Comienza  <span class='text-information-link'>registrando una. </span></p>")
        : "";
      this.sTextEmpty = sTextemptyTemp;
    },
    // FUNCTION OF FOOTER TABLE
    changeTable(e) {
      this.iItemsPerPage = e;
      this.iCurrentPage = 1;
      this.getAccounts();
    },
    backPage() {
      if (this.iCurrentPage > 1) {
        this.aTable = [];
        this.bLoadingTable = true;
        this.iCurrentPage--;
      }
    },
    nextPage() {
      if (this.iCurrentPage < this.numPages) {
        this.aTable = [];
        this.bLoadingTable = true;
        this.iCurrentPage++;
      }
    },
    firstPage() {
      this.iCurrentPage = 1;
    },
    lastPage() {
      this.iCurrentPage = this.numPages;
    },
  },
  computed: {
    refreshTable() {
      return this.$store.state.refresh;
    },
    bShowAccount() {
      return this.$store.state.bShowAccount;
    },
    bAdminAccount() {
      return this.$store.state.bAdminAccount;
    },
  },
  watch: {
    bAdminAccount() {
      this.setTextEmpty();
    },
    sInstitution() {
      this.getAccounts();
    },
    sCurrency() {
      this.getAccounts();
    },
    bAccountBalance() {
      this.getAccounts();
    },
    sAccountTypeId() {
      this.getAccounts();
    },
    refreshTable() {
      this.getAccounts();
    },
    sSearch() {
      this.iCurrentPage = 1;
      this.getAccounts();
    },
    iCurrentPage() {
      this.getAccounts();
    },
  },
};
</script>
<style scoped>
.content-account {
  /* background-color: darkslategrey; */
  padding: 0px 30px 0px 35px;
}

.class-in {
  color: var(--primary-color-text-in) !important;
}

.class-out {
  color: var(--primary-color-text-out) !important;
}

.content-btns-actions-accounts {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}
.content-btn-excel {
  width: 200px;
}

/*#region MODO RESPONSIVO MAQUETA */

/*#region XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }
  .content-btns-actions-accounts {
    display: block;
    justify-content: flex-end;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .content-btn-excel {
    width: 100%;
  }
}

/*#endregion XS */

/*#region SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }
}

/*#endregion SM */

/*#region MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/*#endregion MD */

/*#region LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/*#endregion LG */

/*#region XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}

/*#endregion XL */

/*#endregion MODO RESPONSIVO  MAQUETA */
</style>
